.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.custom_detail-text span,
.custom_detail-text p,
.custom_detail-text h1,
.custom_detail-text h2,
.custom_detail-text h3,
.custom_detail-text h4,
.custom_detail-text h5,
.custom_detail-text h6,
.custom_detail-text strong,
.custom_detail-text article {
  color: #bdbdc2 !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-bar__raggable-area {
  -webkit-app-region: drag;
  /* Additional styling as needed */
}

.title-bar__no-drag {
  -webkit-app-region: no-drag;
  /* Button styling */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Apply the spin animation to an element with the class .spinner */
.spinner {
  animation: spin 1s linear infinite;
}
